<script>
    import flagEn from '../assets/flag-en.svg';
    import flagBe from '../assets/flag-be.svg';
    import DropdownV2 from '@/components/DropdownV2.vue';
    import DropdownGroup from '@/components/DropdownGroup';
    import DropdownItem from '@/components/DropdownItem';
    import { simpleLocale, setLocale } from '@/i18n';
    import { setLocale as setVeeValidateLocale } from '@vee-validate/i18n';

    export default {
        name: 'LanguageSwitcher',
        props: {
            transparent: Boolean,
            whiteName: Boolean,
        },
        components: {
            DropdownItem,
            DropdownV2,
            DropdownGroup,
        },
        data () {
            return {
                flagEn,
                flagBe,
                flagFr: flagBe,
            };
        },
        computed: {
            lang () {
                return simpleLocale(this.$i18n.locale);
            },
            flagSrc () {
                return {
                    en: flagEn,
                    fr: flagBe,
                    nl: flagBe,
                }[this.lang];
            },
        },
        methods: {
            setLang (lang) {
                setLocale(lang);
                setVeeValidateLocale(lang);
            },
        },
    };
</script>

<template>
    <DropdownV2
        class='z-50 py-3 sm:py-0 mb-6 sm:mb-0 language-switch'
        button-small
        :value='lang'
        :direction='$store.state.gui === "mobile" ? "topcenter" : "right"'
        icon=''
        :transparent='transparent'
        :white-name='whiteName'
        @changed='setLang'
        button-extra-classes='font-medium'
    >
        <DropdownGroup>
            <DropdownItem
                id='en'
                class='whitespace-nowrap'
                name='English'
            >
                <img class='flag' :src='flagEn' alt='English'>
                <span class='lang-text'>
                    English
                </span>
            </DropdownItem>
            <DropdownItem
                id='nl'
                class='whitespace-nowrap'
                name='Nederlands'
            >
                <img class='flag' :src='flagBe' alt='Nederlands'>
                <span class='lang-text'>
                    Nederlands
                </span>
            </DropdownItem>
            <DropdownItem
                id='fr'
                class='whitespace-nowrap'
                name='Français'
            >
                <img class='flag' :src='flagFr' alt='Français'>
                <span class='lang-text'>
                    Français
                </span>
            </DropdownItem>
        </DropdownGroup>
    </DropdownV2>
</template>

<style>
    .cb-lang {
        margin-right: 10px;
    }

    .lang-text {
        @apply ml-1;
    }
</style>
