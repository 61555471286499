<script>
import Icon from '@/components/Icon.vue';
import Tooltip from '@/components/Tooltip.vue';

export default {
    name: 'DropdownItemComponent',
    props: {
        id: String,
        default: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        name: String,
        icon: String,
        disabledInfo: {
            type: String,
            default: '',
        },
        info: {
            type: String,
            default: null,
        },
    },
    components: {
        Icon,
        Tooltip,
    },
    mounted () {
        if (!this.disabled && (this.default || this.selected === this.id)) {
            this.activate();
        }

        // register the items in the parent dropdown
        this.options.push(this);
    },
    inject: ['change', 'selected', 'options'],
    emits: ['click'],
    methods: {
        click () {
            if (this.disabled) {
                return;
            }
            this.$emit('click', this.id);
            this.activate();
        },
        activate () {
            if (!this.disabled) {
                this.change(this.id);
            }
        },
    },
};
</script>

<template>
    <div
        @click='click'
        class='dropdown-v2-item flex items-center'
        :class='{ "dropdown-v2-item-disabled": disabled }'
    >
        <Icon v-if='icon' :name='icon' class='mr-2 -ml-1' width='w-5' />
        <slot></slot>
        <Tooltip v-if='disabled && disabledInfo.length > 0' class='disabled'>
            <template #trigger>
                <Icon name='InformationCircle' class='ml-2' width='w-6' family='outline' />
            </template>
            <template #content>
                {{ disabledInfo }}
            </template>
        </Tooltip>
        <Tooltip v-else-if='info' class='info'>
            <template #trigger>
                <Icon name='InformationCircle' class='ml-2' width='w-6' family='outline' />
            </template>
            <template #content>
                {{ info }}
            </template>
        </Tooltip>
    </div>
</template>

<style lang="postcss" scoped>
.dropdown-v2-item {
    @apply py-2 px-4 rounded-lg cursor-pointer text-grey-700 transition-colors duration-150 ease-linear;

    &:hover {
        @apply bg-grey-50;
    }
}

.dropdown-v2-item-disabled {
    @apply cursor-not-allowed text-grey-300;

    &:hover {
        @apply bg-transparent;
    }
}
</style>
