<script setup>
import { ref, defineEmits } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import DropdownV2 from '@/components/DropdownV2.vue';
import DropdownItem from '@/components/DropdownItem';
import DropdownGroup from '@/components/DropdownGroup';
import LanguageSwitcher from './LanguageSwitcher.vue';
import Loader from '@/components/Loader.vue';
import LineDivider from '@/components/LineDivider.vue';

const props = defineProps({
    user: Object,
    noNav: {
        type: Boolean,
        default: false,
    },
    noLogin: {
        type: Boolean,
        default: false,
    },
    helpUrl: String,
    userOrganizations: {
        type: Array,
        default: () => [],
    },
    organization: Object,
    reseller: Object,
    transparent: Boolean,
});

const router = useRouter();
const route = useRoute();
const store = useStore();

const emits = defineEmits(['openNewEnvironmentPopup']);

const active = ref(false);

const logout = async () => {
    await store.dispatch('logout');
    router.push('/login');
};

const toggleMenu = () => {
    active.value = !active.value;
};

const goToHomePage = () => {
    if (props.noLogin) return;
    router.push('/');
};

const environmentMustChange = (environmentId) => {
    if (environmentId !== route.params.environmentId) {
        router.push(`/organization/${route.params.organizationId}/environment/${environmentId}`);
    }
};

const organizationMustChange = (organizationId) => {
    if (organizationId !== route.params.organizationId) {
        router.push(`/organization/${organizationId}/`);
    }
};
</script>

<template>
    <nav class='menu' :class='[{"active": active, transparent }]'>
        <span class='corner-left' v-if='!transparent'><img src='@/assets/corner.svg'></span>
        <span class='corner-right' v-if='!transparent'><img src='@/assets/corner.svg'></span>
        <span class='menu-shadow' v-if='!transparent'></span>
        <div class='flex menu-header'>
            <img
                :src='require(transparent ? "@/assets/mycodabox-logo-small.svg" : "@/assets/mycodabox-logo-white-small.svg")'
                alt='CodaBox logo'
                class='h-7 mr-3 cursor-pointer'
                @click='goToHomePage'
            >
            <div v-if='organization'>
                <DropdownV2
                    :value='organization.id'
                    white-name
                    class='organization-switch z-50 lg:py-0 font-medium'
                    semi-transparent
                    button-small
                    icon='sort'
                    button-extra-classes='max-w-xs md:max-w-sm lg:max-w-sm xl:max-w-3xl overflow-hidden overflow-ellipsis'
                    menu-extra-classes='w-64 max-w-xs md:max-w-sm lg:max-w-2xl xl:max-w-6xl'
                    @changed='(val) => organizationMustChange(val)'
                >
                    <DropdownGroup>
                        <DropdownItem
                            v-for='org in userOrganizations'
                            :id='org.id'
                            :key='org.id'
                            :name='org.enterpriseName'
                        >
                            {{ org.enterpriseName }}
                        </DropdownItem>
                    </DropdownGroup>
                </DropdownV2>
            </div>
            <div v-if='reseller'>
                <router-link to='/' class='px-3 py-1 flex items-center font-bold text-xl text-white hover:no-underline focus:no-underline hover:text-white focus:text-white ml-3 mr-4 bg-white bg-opacity-10 rounded'>
                    {{ reseller.name }}
                </router-link>
            </div>
            <button class='menu-button' @click='toggleMenu'>
                <span></span>
                <span></span>
                <span></span>
            </button>
        </div>

        <div class='menu-body'>
            <div
                v-if='organization'
                class='flex flex-col lg:flex-row items-center justify-center lg:h-full mt-auto lg:mr-auto lg:mt-0'
            >
                <DropdownV2
                    :name='$t("nav-environments-selector")'
                    white-name
                    class='ml-3 z-50 lg:py-0 font-medium environment-switch'
                    transparent
                    button-small
                    no-selected
                    menu-extra-classes='max-w-xs md:max-w-sm lg:max-w-sm xl:max-w-3xl'
                    :accordion='$store.state.gui === "mobile"'
                >
                    <DropdownGroup scrollable v-if='organization.fiduciaries && organization.fiduciaries.length > 0'>
                        <DropdownItem
                            v-for='(environment) in organization.fiduciaries'
                            :id='environment.id'
                            :key='environment.id'
                            class='font-normal whitespace-nowrap'
                            @click='(val) => environmentMustChange(val)'
                            :name='environment.name'
                        >
                            {{ environment.name }}
                        </DropdownItem>
                    </DropdownGroup>
                    <DropdownGroup v-else>
                        <DropdownItem
                            disabled
                            class='font-normal whitespace-nowrap'
                        >
                            {{ $t('nav-environments-selector-empty') }}
                        </DropdownItem>
                    </DropdownGroup>
                    <template v-if='$store.state.flags["new-env"] && !organization.isDirectCustomer'>
                        <LineDivider />
                        <DropdownGroup>
                            <DropdownItem
                                icon='Plus'
                                class='font-normal whitespace-nowrap'
                                @click='emits("openNewEnvironmentPopup")'
                                :info='$t("add-new-environment.btn-nav-add-new-env-info")'
                                :disabled='!$route.meta.authGuard?.isCurrentOrgAdmin'
                                :disabled-info='$t("add-new-environment.btn-nav-add-new-env-info-disabled")'
                            >
                                {{ $t('add-new-environment.btn-nav-add-new-env') }}
                            </DropdownItem>
                        </DropdownGroup>
                    </template>
                </DropdownV2>
                <DropdownV2
                    v-if='$route.meta.authGuard && $route.meta.authGuard.isCurrentOrgAdmin'
                    :name='$t("nav-org-administration")'
                    white-name
                    class='z-50 py-3 lg:py-0 font-medium organization-administration'
                    transparent
                    button-small
                    no-selected
                    :accordion='$store.state.gui === "mobile"'
                >
                    <DropdownGroup>
                        <DropdownItem
                            id='users'
                            @click='() => $router.push(`/organization/${organization.id}/administration`)'
                            class='font-normal whitespace-nowrap'
                        >
                            {{ $t('nav-organization-administration-users') }}
                        </DropdownItem>
                        <DropdownItem
                            id='delivery'
                            @click='() => $router.push(`/organization/${organization.id}/administration/connect`)'
                            class='font-normal whitespace-nowrap'
                        >
                            {{ $t('nav-organization-connect') }}
                        </DropdownItem>
                        <DropdownItem
                            id='information'
                            @click='() => $router.push(`/organization/${organization.id}/administration/information`)'
                            class='font-normal whitespace-nowrap'
                        >
                            {{ $t('nav-organization-administration-information') }}
                        </DropdownItem>
                        <DropdownItem
                            v-if='organization.syncToolConnections?.length > 0'
                            id='connections'
                            @click='() => $router.push(`/organization/${organization.id}/administration/connections`)'
                            class='font-normal whitespace-nowrap'
                        >
                            {{ $t('nav-organization-administration-connections') }}
                        </DropdownItem>
                    </DropdownGroup>
                </DropdownV2>
            </div>
            <div class='flex flex-col lg:flex-row lg:ml-auto items-center mb-auto lg:mb-0'>
                <a class='menu-item' target='blank' :href='helpUrl' v-if='helpUrl'>
                    <i class='fa fa-question-circle mr-3'></i>
                    <span class='leading-none'>{{ $t('nav-fidu-help') }}</span>
                </a>
                <DropdownV2
                    v-if='user && user.username'
                    :name='user.email'
                    white-name
                    class='z-50 py-3 lg:py-0 mb-6 lg:mb-0 font-medium user-menu'
                    transparent
                    button-small
                    no-selected
                    :accordion='$store.state.gui === "mobile"'
                >
                    <DropdownGroup>
                        <DropdownItem
                            id='nav-user-settings-info'
                            @click='() => $store.commit("openUserSettingsInfoPopup")'
                            class='font-normal'
                        >
                            {{ $t('nav-user-settings-info') }}
                        </DropdownItem>
                        <DropdownItem
                            id='change-password'
                            @click='() => $store.commit("openUserSettingsChangePasswordPopup")'
                            class='font-normal'
                        >
                            {{ $t('nav-change-password') }}
                        </DropdownItem>
                        <DropdownItem
                            id='nav-user-settings-2fa'
                            @click='() => $store.commit("openUserSettings2faPopup")'
                            class='font-normal'
                        >
                            {{ $t('nav-user-settings-2fa') }}
                        </DropdownItem>
                        <DropdownItem
                            id='logout'
                            @click='() => logout()'
                            class='font-normal'
                        >
                            {{ $t('nav-logout') }}
                        </DropdownItem>
                    </DropdownGroup>
                </DropdownV2>
                <language-switcher :transparent='!transparent' :white-name='!transparent' />
            </div>
        </div>
        <Loader />
    </nav>
</template>

<style lang='postcss' scoped>
.menu {
    @apply px-6 bg-blue-500 fixed top-0 right-0 z-10 left-0 w-full h-16;
    z-index: 1000;

    @apply lg:pl-6 lg:pr-3 lg:flex lg:items-center lg:overflow-visible;
}

.menu-shadow {
    @apply hidden md:block absolute;
    pointer-events: none;
    content: ' ';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
}

.menu-shadow:after {
    @apply absolute;
    content: ' ';
    bottom: 0;
    left: 2%;
    right: 2%;
    box-shadow: rgba(0, 0, 0, .2) 0px 8px 36px 13px;
}

.menu.transparent {
    @apply bg-grey-30;
}

.menu-header {
    @apply h-16 flex items-center;
}

.menu-button {
    @apply flex lg:hidden flex-col justify-around items-end w-6 h-6 bg-transparent border-none focus:outline-none ml-auto;

    > span {
        @apply w-6 bg-white rounded-sm;
        height: 2px;
        transition: all ease-in-out .2s;
    }

    > span:nth-child(2) {
        @apply w-5;
    }
}

.menu-body {
    @apply flex items-center flex-col flex h-[calc(100%-4rem)] justify-center text-lg py-6 mt-16 overflow-hidden opacity-0 pointer-events-none;
    @apply lg:flex-grow lg:flex-row lg:h-auto lg:text-base lg:py-0 lg:mt-0 lg:overflow-visible lg:opacity-100 lg:pointer-events-auto;
}
.menu-item {
    @apply flex items-center text-white no-underline px-3 py-3 font-medium my-3 md:my-0;
    position: relative;

    @apply lg:py-0;

    &:before {
        @apply bg-blue-200;
        content: ' ';
        display: block;
        height: 6px;
        position: absolute;
        left: 20%;
        right: 20%;
        bottom: 0;
        border-radius: 6px 6px 0 0;
        opacity: 0;
    }
}

.menu-item-active,
.menu-item:hover {
    &:before {
        opacity: 1;
    }
}

.active {
    @apply h-screen;
    @apply lg:h-auto;
}

.active .menu-body {
    @apply mt-0 opacity-100 pointer-events-auto;
}

.active .menu-button {
    > span:nth-child(1) {
        transform: translateY(7px) rotate(45deg);
    }

    > span:nth-child(2) {
        opacity: 0;
        transform: translateX(-10px);
    }

    > span:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
    }
}

.corner-left img {
    @apply hidden md:block absolute;
    bottom: -10px;
    left: 0;
    height: 10px;
    width: 10px;
}
.corner-right img {
    @apply hidden md:block absolute;
    bottom: -10px;
    right: 0;
    height: 10px;
    width: 10px;
    transform: scaleX(-1);
}
</style>
